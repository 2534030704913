* {
    margin:0px;
    padding:0px;
}

form {
    width:360px;
    height:640px;
    background-color:#fff;
    border:solid 1px grey;
    margin:0 auto;
}

.img__unchecked{   
   width: 28px;
    height: 28px;
    padding: 5px;
    margin-right:8px;
    margin-bottom:4px;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;    
    border: 2px solid #33475B;
    opacity: 1;
    cursor: pointer; 
    outline: none;
  } 

  .label__unChecked:before {
    width: 24px;
    height:24px;    
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none;
    border-radius:50%;
    margin-right:8px;
    margin-bottom:-7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #33475B;   
    opacity: 1;
    cursor: pointer;
    
}

.lable-checked{    
    color: #fff;
    width:298px;
    line-height:46px;    
    padding-left:15px;  
    display:block;
    border: 1px solid var(--unnamed-color-6bc464);
    background: #6BC464 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000005C;
    border: 1px solid #6BC464;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
   
}

.img__checked {
    width: 28px;
    height:28px;
    background-color:#f0f0f0;    
    padding: 4px;
    border-radius:50%;
    margin-right:8px;
    margin-bottom: 4px;
    border:solid 2px #fff;
    outline: none;
    cursor: pointer;
}

.label__checked {
    color: #fff;
    width:298px;
    line-height:46px;
    margin-top: 15px;
    padding-left:15px;  
    display:block; 
    border: 1px solid var(--unnamed-color-6bc464);
    background: #6BC464 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000005C;
    border: 1px solid #6BC464;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
}


.label__unChecked {    
    width:298px;
    line-height:46px;
    margin-top: 15px;
    padding-left:15px;    
    display:block;   
    border: 1px solid var(--unnamed-color-33475b);
    background: #33475B1C 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000001A;
    border: 1px solid #33475B;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
    
} 


 .radio {
    width: 24px;
    height:24px;    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius:50%;
    margin-right:8px;
    margin-bottom:-7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #33475B;   
    opacity: 1;
}

.bg_color {    
    color: #fff;
    width:298px;
    line-height:46px;
    margin-left:20px;
    margin-top:30px;
    padding-left:15px;  
    display:block;
    border: 1px solid var(--unnamed-color-6bc464);
    background: #6BC464 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000005C;
    border: 1px solid #6BC464;
    border-radius: 4px;
    opacity: 1;
}

.radio2 {
    width: 20px;
    height:20px;
    background-color:#6BC464;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius:50%;
    margin-right:8px;
    margin-bottom:-4px;
    border:solid 4px #fff;
}


 

