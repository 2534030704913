.selected-date{
    display: flex;
    padding: 0px 15px;
    font-size: 30px;
    align-items: center;
    width: 260px;
    height: 50px;
    margin-bottom: 0;
    color: #FFF;
    background-color: #6c66ee;
}

.custom-calendar-style{
    width: 260px;
    height: auto;
    border: none;
}

.custom-calendar-style .react-calendar__tile{
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
    padding: 9px 11px;
    line-height: 18px;
    height: 34px;
}

.custom-calendar-style .react-calendar__tile--active{
    background-color: #6c66ee;
}
.react-calendar{
    font-family: Apercu-Pro-Regular;
    font-size: 14px;

}