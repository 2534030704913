.chatbot-container{
    margin: 0 auto;
    width: 360px;
    min-height: 95vh;
    height: auto;
    padding: 18px;
    position: relative;
}
.bottom-container{
    margin: 0 auto;
    width: 360px;
    min-height: 640px;
    height: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

}
.company-logo img{
 margin-top:105px;
    min-width:201px;
    min-height: 85px;
    text-align: center;

}
.home-text p{
    font-family: 'Apercu-Pro-Regular',sans-serif;
    font-weight: normal;
    font-size:21px;
    margin-top: 190px;
    margin-bottom: 18px;
    color:#000;
    line-height: 29px;

}

.home-text-universal p{
    font-family: 'Apercu-Pro-Regular',sans-serif;
    font-weight: normal;
    font-size:21px;
    margin-bottom: 18px;
    color:#000;
    line-height: 29px;
    margin-top: 160px;
}

.decline-text-universal p{
    font-family: 'Apercu-Pro-Regular',sans-serif;
    font-weight: normal;
    font-size:21px;
    margin-bottom: 18px;
    color:#000;
    line-height: 29px;
}

.PB-logo{
    position: absolute;
    bottom: 18px;
    right: 18px;
}
.button-orange button{
    min-width:199px;
    width: max-content;
    padding: 0px 14px;
    height: 50px;
    background: #E7863F;
    color:#fff;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 10px #0000001A;
    font-family:'Apercu-Pro-Black',sans-serif;
    font-size:16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

}
/*Start consent page styles*/
.start-page-logo img{
    width:120px;
    height: 50px;
    margin-bottom: 23px;

}

.company-icon img{
    margin-right: 7px;
}
.company-icon span{
    font-size:16px;
    font-family: 'Apercu-Pro-Regular',sans-serif;
    color:#33475B;

}

.job-details h4{
    font-family: Apercu-Pro-Medium;
    font-weight: 500;
    margin-bottom: 11px;
}
.description{
    margin-bottom: 200px;
}
.description h6{
    font-family: Apercu-Pro-Medium;
    margin-top: 27px;
    font-size: 16px;
    font-weight: 500;
    color:#000;
    line-height: 1em;
    margin-bottom: 15px;
}

.description p{
 font-size: 14px;
    color:#74818D;
    font-weight: normal;
    line-height: 22px;
}

.description ul {
    list-style: none;
    padding: 0;
}

.description ul li::before {
    content: "\2022";
    color: #2F465C;
    font-size: xx-large;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    padding-top: 3px;

}

.description ul li {
    font-size: 14px;
    color:#74818D;
    font-family: Apercu-Pro-Regular;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 15px;

}

.floating-box{
    width:350px;
    height: auto;
    position:fixed;
    bottom:0;
    background: #fff;
    box-shadow:0 -6px 4px -5px #ddd;
    margin-bottom: 10px;
    padding-bottom: 20px;

}
.floating-box p {
    font-size: 21px;
    font-family: Apercu-Pro-Regular;
    color:#000;
    line-height: 29px;
    margin-bottom: 10px;
    padding-right:25px;
    padding-top: 22px;
}
.floating-box span{
    font-size: 16px;
    font-family: Apercu-Pro-Regular;
    color:#464646;
}
.consent-buttons {
    margin-top: 20px;
}
.apply-btn{
    width:213px;
    height: 46px;
    background:#6BC46439;
    border:1px solid #6BC464;
    border-radius: 4px;
    padding: 9px 10px;
   display: inline-flex;
    cursor: pointer;
    margin-bottom: 14px;
}
.apply-btn span{
    background: #6BC464;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    display: flex

}
.apply-btn input{
    font-family: 'Apercu-Pro-Regular', sans-serif;
    font-size:16px;
    color:#33475B;
    outline: none;
    border: none;
    background: transparent;
    padding-left: 13px;
    cursor: pointer;

}
.sorry-btn{
    width:213px;
    height: 46px;
    background:#F9357138;
    border:1px solid #F93571;
    border-radius: 4px;
    padding: 9px 10px;
    display: inline-flex;
    cursor: pointer;
}
.sorry-btn span{
    background: #F93571;
    border-radius: 50%;
    width: 28px!important;
    height: 28px;
    align-items: center;
    justify-content: center;
    display: flex
}
.sorry-btn input{
    font-family: 'Apercu-Pro-Regular', sans-serif;
    font-size:16px;
    color:#33475B;
    outline: none;
    border: none;
    background: transparent;
    padding-left: 13px;
    padding-right: 0;
    cursor: pointer;
}



/* Styles for Radio button Screen */

.question-container {
    margin: 0 auto;
    width: 321px;
    min-height: 79px;
    height: auto;
}

.question{
    font-size: 21px;
    font-family: 'Apercu-Pro-Regular',sans-serif;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    line-height: 1.2em;
    font-weight: 500;
}
.objective {
    font-size: 12px;
    font-family: 'Apercu-Pro-Regular',sans-serif;
}

/* Ok button styles */

.ok-button {
    color:#fff;
    min-width:81px;
    width: auto;
    line-height:46px;
    border:none;
    font-size:16px;
    padding: 0px 20px;
    background: #E7863F 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000001A;
    border-radius: 4px;
    opacity: 1;
    font-weight: 600;
    text-align: left;
}

.ok-button-disabled {
    min-width:81px;
    width: auto;
    line-height:46px;
    font-size:16px;
    text-align: left;
    padding: 0px 20px;
    background: #E8EBED 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000001A;
    border: 1px solid #33475B34;
    border-radius: 4px;
    opacity: 1;
    letter-spacing: -0.1px;
    /* color: #33475B34;   */
    color: #14161934;
    font-weight: 600;
}

/*Start chatbot screen styles*/

.start-chatbot h4{
    margin-top: 165px;
    font-weight: normal;
    font-family: 'Apercu-Pro-Regular', sans-serif;
    margin-bottom: 17px;
}
.start-chatbot p{
    font-size: 16px;
    color: #464646;
    line-height: 22px;
    margin-top: 0;
}
.key-point{
    display: inline-flex;
    margin-bottom: 16px;
    width:100%;

}
.grey-crl{
    width: 24px;
    height: 24px;
    background: white;
    border: 2px solid #2F465C;
    border-radius: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
}
span.key-text {
    font-size: 13px;
    color:#464646;
    line-height: 1.3em;
    padding-top: 5px;
    padding-left: 10px;
    width:94%;
}
.key-points-list p{
    font-size: 13px;
    color:#464646;
    line-height: 1.3em;
}

.sticky-footer{
    width: 340px;
    background: white;
    position:fixed;
    bottom:0;
    padding: 20px 20px 20px 0;

}

/*Hr Selection screen styles*/

.Hr-selection-screen h4{
    font-size: 22px;
    font-family: Apercu-Pro-Regular;
    color: #000000;
    font-weight: normal;
}
.Hr-selection-screen p{
    font-size: 16px;
    color: #464646;
}
.section-outer{
    width: 322px;
    height:146px ;
    margin-bottom: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 11px #000000AA;
    opacity: 1;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.hr-section h4{
    font-size:22px;
}

.q-text span{
    font-family: Apercu-Pro-Regular;
    font-size: 18px;
    color: #000;
    font-weight: normal;
}
.section-heading{
width: 60.4%;
}
.section-img{
    width: 33.5%;
    margin-top: 3px;
}

.inactive{opacity: 0.5; cursor: default;}

/*Decline screen*/

.social-links{
    width:60%;
    float:left;
    margin-top: 30px;

}

.social-links span{
    margin-right:10px;
    cursor: pointer;
}
p.question-breadcrumb {
    font-size: 12px;
    margin-bottom: 55px;
}

/* ProgressBar Styles */


.progress-bar {
    background-color: #2BCD65 !important;
}


/*Loader Styles start*/

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    perspective: 2000px;
    transform: rotateZ(0) rotateX(-30deg) rotateY(-45deg);
    animation: AnimateContainer 3s infinite;
    z-index: 1;
}

.loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    transform: translate3d(0em, 3em, 1.5em);
}
.loader-wrapper:last-child {
    transform: rotateY(-90deg) rotateX(90deg) translate3d(0, 3em, 1.5em);
}
.loader-wrapper:first-child {
    transform: rotateZ(-90deg) rotateX(-90deg) translate3d(0, 3em, 1.5em);
}
.loader-wrapper:nth-child(1) .cube {
    background-color: #4B7DC6;
}
.loader-wrapper:nth-child(1) .cube:before {
    background-color: #498CD2
}
.loader-wrapper:nth-child(1) .cube:after {
    background-color: #63C19F;
}
.loader-wrapper:nth-child(2) .cube {
    background-color: #FBC52D;
}
.loader-wrapper:nth-child(2) .cube:before {
    background-color: #F1B217;
}
.loader-wrapper:nth-child(2) .cube:after {
    background-color: 	#258B75;
}
.loader-wrapper:nth-child(3) .cube {
    background-color: #E23958;
}
.loader-wrapper:nth-child(3) .cube:before {
    background-color: #CC3153;
}
.loader-wrapper:nth-child(3) .cube:after {
    background-color: 	#258B75;
}

.cube {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    width: 3em;
    height: 3em;
    transform: translate3d(-1.5em, -3em, 0) scaleY(0.01);
    animation: AnimateCube 3s infinite;
}
.cube:before, .cube:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}
.cube:before {
    left: 100%;
    bottom: 0;
    transform: rotateY(90deg);
    transform-origin: 0 50%;
}
.cube:after {
    left: 0;
    bottom: 100%;
    transform: rotateX(90deg);
    transform-origin: 0 100%;
}

@keyframes AnimateContainer {
    100% {
        transform: rotateZ(360deg) rotateX(-30deg) rotateY(-45deg);
    }
}
@keyframes AnimateCube {
    12.5% {
        transform: translate3d(-50%, -50%, 0) scaleY(1);
    }
    25% {
        transform: translate3d(-50%, -50%, 0) scaleZ(2);
    }
    37.5% {
        transform: translate3d(-50%, -50%, 0) scaleZ(1);
    }
    50% {
        transform: translate3d(0, -50%, 0) scaleX(2);
    }
    62.5% {
        transform: translate3d(-50%, -50%, 0) scaleX(1);
    }
    75% {
        transform: translate3d(-50%, 0, 0) scaleY(2);
    }
    87.5% {
        transform: translate3d(-1.5em, -3em, 0) scaleY(0.01);
    }
    100% {
        transform: translate3d(-1.5em, -3em, 0) scaleY(0.01);
    }
}

.loader-background{
    background: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
}
/*Loader styles End*/
.c-name{
    font-weight: bold;
    text-transform: capitalize;
    color: #000!important;
    font-size: 21px!important;
}
.c-name-description{
    font-size: 14px;
}
.key-points-list{
    margin-bottom: 80px;
}

h4.q-text {
    font-size: 21px;
    font-weight: normal!important;
    font-family: Apercu-Pro-Regular;
}

/* Interview Styles */
.fixed-bottom {
    position: fixed;
    margin: 0 auto;
    width: 360px;
    bottom: 0;
    background-color: #ffffff;
}

.selection-box{
    width:319px;
    height: 50px;
    border:1px solid #E0E4E7;
    border-radius:5px;
    display: flex;
    align-items: center;
    padding-left:10px;
    font-family: "Roboto", sans-serif;
    color: #747b83 !important;
    margin: 10px auto;
}

.submit-button{
    width: 360px;
    height: 50px;
    background-color: #E7863F;
    font-family: Apercu-Pro-Bold;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: left;
    box-shadow: 0px 5px 10px #0000001A;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-button-disabled{
    background: #E8EBED 0% 0% no-repeat padding-box !important;
    color: #14161934 !important;
}
.thank-you-image
{
    position: absolute;
    right: 0;
    margin-top: 50px;
}



@media only screen and (max-width: 500px) {
    .fixed-bottom{
        position: fixed;
        margin: 0 auto;
        width: 100%;
        bottom: 0;
        background-color: #ffffff;
    }
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
    u ~ div .chatbot-screen {
        min-width: 320px !important;
    }
    .submit-button{
        width:100%;
    }
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    u ~ div .chatbot-screen {
        min-width: 375px !important;
    }
    .submit-button{
        width:100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
    u ~ div .chatbot-screen {
        min-width: 414px !important;

    }
    .submit-button{
        width:100%;
    }

}

/* Interview Style ends */

h3.skills-text
{
    font-family: Apercu-Pro-Regular;
    font-weight:normal ;
    margin-top: 20px;
    text-transform: capitalize;
}
.q-text p{
    font-family: Apercu-Pro-Regular;
    font-weight:normal ;
    font-size: 21px;
    color: #000;
}

/*
Skill Style
 */
.skills-style{
    display:        flex;
    align-items:    center;
    width:          322px;
    height:         44px;
    border:         1px solid #33475b;
    background:     #33475b1c 0% 0% no-repeat padding-box;
    box-shadow:     0px 5px 10px #0000001a;
    border-radius:  4px;
    color:          #33475b;
    transition:     100ms;
    padding:        15px 12px;
    margin-bottom:  13px;
}

/* React Transition Group Styles */

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 600ms linear;
}

/* enter */
.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 600ms linear;
}

/* exit */
.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 600ms linear;
}

.anim_mobile_selectors-enter {
    opacity: 0.1;
    transition: opacity 600ms linear;
}
  
.anim_mobile_selectors-enter-active, .anim_mobile_selectors-enter-done {
    opacity: 1;
}

.anim_mobile_selectors-exit {
    opacity: 1;
}

.anim_mobile_selectors-exit-active {
    opacity: 0.1;
    transition: opacity 600ms linear;
}

.box {
    position: relative;
}

.box-entering {
    animation-name: fadeInTransition;
    animation-duration: 600ms;
}

.box-exiting {
    animation-name: fadeOutTransition;
    animation-duration: 600ms;
}

@keyframes fadeInTransition {
    0% {bottom: -700px;}
    100% {bottom: 0px;}
}

@keyframes fadeOutTransition {
    0% {top: 0px;}
    100% {top: -700px;}
}

.resume-page-text {
    margin-bottom: 7px;
    color: #464646;
}

.resume-upload-text p{
    color: #000!important;
    font-size: 21px;
    line-height: 1.2em;
}

/* Style for 404 page */
   .not-found-error-header{
    font-family: Roboto, sans-serif;
    font-size: 150px;
    line-height: 0.2;
    letter-spacing: normal;
    text-align: left;
    margin-top:220px;
   }
   .not-found-error-text{
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    padding-top: 96px;
    padding-left: .1em;
    width:470px;
  
   }
   .oops-img{
    margin-top:155px;
   }
  