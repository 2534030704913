.skillQuestion__container{
    width: 132px;
    height: 118px;
    box-shadow: 0px 5px 11px #00000024;
    border-radius: 4px 4px 0px 0px;
    margin-bottom:22px ;
    clear: left;
    cursor: pointer;
}

.skill__container {
    width: 132px;
    height: 58px;
    background: #33475B 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 11px #00000024;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;    
}
.skill__container-complete {
    width: 132px;
    height: 58px;
    background: #6BC464 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 11px #00000024;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    color: #fff;    
}

.skill__text {
    opacity: 1;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    letter-spacing: 0px;
    margin: auto;
    text-align: center;
}

.question__container{
   text-align: center;  
}

.question__text {
    font-size: 13px;
    margin: auto;
    color: #000000;    
    line-height: 60px;
}

.question__text-complete{
    font-size: 13px;
    margin: auto;
    color: #000000;    
    line-height: 60px;
}

.text-complete-div {
    display: flex;
    margin: 0 auto;
}

.checkmark-complete {
    width: 15px;
    height: 15px;
    padding: 2px;
    margin-left:15px;
    margin-top:22px;
    margin-right: -25px;   
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;    
    border: 2px solid #33475B;
    opacity: 1;    
    outline: none;
}