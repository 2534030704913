@font-face {
  font-family: Apercu-Pro-Regular;
  src: url('/src/assets/font/ApercuPro-Regular.woff');
  font-weight: normal;
}
@font-face {
  font-family: Apercu-Pro-Regular;
  src: url('/src/assets/font/ApercuPro-Regular.woff2');
  font-weight: normal;
}
@font-face {
  font-family: Apercu-Pro-Regular;
  src: url('/src/assets/font/ApercuPro-Regular.ttf');
  font-weight: normal;
}
@font-face {
  font-family: Apercu-Pro-Medium;
  src: url('/src/assets/font/ApercuPro-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Apercu-Pro-Medium;
  src: url('/src/assets/font/ApercuPro-Medium.woff');
  font-weight: 500;
}
@font-face {
  font-family: Apercu-Pro-Medium;
  src: url('/src/assets/font/ApercuPro-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: Apercu-Pro-Bold;
  src: url('/src/assets/font/ApercuPro-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: Apercu-Pro-Bold;
  src: url('/src/assets/font/ApercuPro-Bold.woff');
  font-weight: bold;
}
@font-face {
  font-family: Apercu-Pro-Bold;
  src: url('/src/assets/font/ApercuPro-Bold.woff2');
  font-weight: bold;
}
@font-face {
  font-family: Apercu-Pro-Black;
  src: url('/src/assets/font/ApercuPro-Black.woff2');
  font-weight: bold;
}
@font-face {
  font-family: Apercu-Pro-Black;
  src: url('/src/assets/font/ApercuPro-Black.woff2');
  font-weight: bold;
}
@font-face {
  font-family: Apercu-Pro-Black;
  src: url('/src/assets/font/ApercuPro-Black.woff2');
  font-weight: bold;
}

@font-face {
  font-family: Apercu-Pro-Italic;
  src: url('/src/assets/font/ApercuPro-Italic.woff');
  font-weight: normal;
}
@font-face {
  font-family: Apercu-Pro-Italic;
  src: url('/src/assets/font/ApercuPro-Italic.woff2');
  font-weight: normal;
}
@font-face {
  font-family: Apercu-Pro-Italic;
  src: url('/src/assets/font/ApercuPro-Italic.ttf');
  font-weight: normal;
}


* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  position: relative;
}
body {
  margin: 0 auto !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
  position: relative;
  font-family:'Apercu-Pro-Regular',sans-serif;
  font-weight: 400;
  color: #747b83;
  font-size: 16px;
  background:#FFFFFF;
}
h1 {
  font-family:'Apercu-Pro-Bold',sans-serif;
  Font-size: 36px;
  Font-weight: 700;
  Line-height:  50px;
  Color: #000;
}
h2{
  font-family: 'Apercu-Pro-Bold',sans-serif;
  Font-size: 30px;
  Font-weight: 700;
  Line-height:  45px;
  Color: #000;
}
h3{
  font-family:'Apercu-Pro-Bold',sans-serif;
  Font-size: 26px;
  Font-weight: 700;
  Line-height:  35px;
  Color: #000;
}
h4{
  font-family:'Apercu-Pro-Bold',sans-serif;
  Font-size: 21px;
  Font-weight: 700;
  Line-height:  25px;
  Color:#000;
}
h5{
  font-family:'Apercu-Pro-Bold',sans-serif;
  Font-size: 18px;
  Font-weight: 700;
  Line-height:  30px;
  Color:#000;
}
p{
  font-family:'Apercu-Pro-Regular',sans-serif;
  Font-size: 14px;
  Font-weight: 400;
  Line-height:  22px;
  Color:#74818D;
}
a{text-decoration:none;
  color:#3b9bff;
  font-family:'Apercu-Pro-Regular',sans-serif;}
a:hover{
  text-decoration:none;
  color:#3b9bff;
}
button{
  outline: none;
  border:none;
  font-family:'Apercu-Pro-Bold',sans-serif;
}
input[type=button]:focus {
  outline: none;
  border:none;
}