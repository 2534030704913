* {
    margin:0px;
    padding:0px;
}

form {
    width:360px;
    height:640px;
    background-color:#fff;
    border:solid 1px grey;
    margin:0 auto;
}

.radio-unchecked{    
    width: 24px;
    height:24px;    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius:50%;
    margin-right:8px;
    margin-bottom:-7px;   
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #33475B;   
    opacity: 1;
    cursor: pointer; 
    outline: none;
  } 

  .unChecked:before {
    width: 24px;
    height:24px;    
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none;
    border-radius:50%;
    margin-right:8px;
    margin-bottom:-7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #33475B;   
    opacity: 1;
    cursor: pointer;
    
}

.lable-checked{    
    color: #fff;
    width:298px;
    line-height:46px;    
    padding-left:15px;  
    display:block;
    border: 1px solid var(--unnamed-color-6bc464);
    background: #6BC464 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000005C;
    border: 1px solid #6BC464;
    border-radius: 4px;
    opacity: 1;
    
   
}

.radio-checked {
    width: 24px;
    height:24px;
    background-color:#6BC464;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius:50%;
    margin-right:8px;
    margin-bottom:-4px;
    border:5px solid #fff;
    outline: none;
    cursor: pointer;
}

.checked {
    color: #fff;
    width:298px;
    margin-bottom: 12px;
    padding:15px 12px;
    display:block;
    background: #6BC464 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000005C;
    border: 1px solid #6BC464;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    overflow-wrap: break-word;
}


.unChecked {    
    width:298px;
    min-height:46px;
    height: auto;
    margin-bottom: 12px;
    padding:15px 12px;
    display:inline-block;
    background: #33475B1C 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000001A;
    border: 1px solid #33475B;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    color:#2F465C;
    overflow-wrap: break-word;
} 


 .radio {
    width: 24px;
    height:24px;    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius:50%;
    margin-right:8px;
    margin-bottom:-7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #33475B;   
    opacity: 1;
}


.radio-text {
    max-width: 220px;
    height: auto;
    font-size: 16px;    
    font-family: Apercu-Pro-Regular;
}
 

