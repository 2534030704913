.checkbox {
  display: flex;
  align-items: center;
  width: 298px;
  min-height: 67px;
  height: auto;
  border: 1px solid #33475b;
  background: #33475b1c 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001a;
  border-radius: 4px;
  color: #33475b;
  transition: 100ms;
  cursor: pointer;  
  padding:15px 12px;
  overflow-wrap: break-word;
}
.indicator {  
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
  }

.checked {
  background: #6bc464 0% 0% no-repeat padding-box;
  border: solid #6bc464;
  box-shadow: 0px 5px 10px #0000005c;
  border-radius: 4px;
  color: #ffffff;
  transition: 100ms;
  overflow-wrap: break-word;
}

.checkbox .border {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 28px;
  height: 28px; 
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #33475b !important;
  border-radius: 4px;
  opacity: 1;
}

.checked .border {
  border: 1px solid #6BC464 !important;
}

.checkbox .label {
  padding-right: 10px; 
  position: relative;
  left: 15px;
  max-width: 221px;  
  height: auto;
  color: #33475b;
  letter-spacing: -0.1px;
  opacity: 1; 
  overflow-wrap: break-word; 
 
}
.label {
  overflow-wrap: break-word;
}
.checked .label {
  color: white;
  overflow-wrap: break-word;
}
