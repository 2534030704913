.range-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.range-input{
    width: 43px;
    height:72px;
    margin: 17px 5px;
    color: #33475B;
    opacity: 0.22;
    font-size: 65px;
}

.range-button{
    text-align: center;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000005C;
    border-radius: 10px;
    opacity: 1;
    font-size: 18px;
    line-height: 18px;
}